<template>
  <section>
    <div>
      <v-sheet class="pa-6 f13" color="#f0f7ff" rounded="lg">{{ questionTitleHolder }}</v-sheet>
    </div>

    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="mt-5">

        <v-textarea auto-grow outlined counter="500" :rules="rules" required v-model="question.question"></v-textarea>

      </div>

      <v-footer app color="transparent" class="mb-4">
        <v-container fluid>
          <v-btn class="f16" block elevation="0" rounded color="primary" large @click="validate()" :disabled="!valid">
            {{ $t("Submit") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-form>

  </section>
</template>


<script>
import apiService from "@/http/ApiService";

export default {
  data() {
    return {

      question: {},
      valid: false,
      questionTitleHolder: this.$t("Your question will be displayed in the list of questions after confirmation without mentioning your name or any identity and is ready to be answered by experts."),
      rules: [
        v => !!v,
        v => (v && v.length <= 500) || this.$t("The maximum length is 500 characters")
      ],
    };
  },
  methods: {
    getAskQuestionGuid() {
      apiService.getAskQuestionGuid()
        .then((response) => {
          if (response.data && response.data.description && response.data.description != "") {
            this.questionTitleHolder = response.data.description
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuestionDetails(id) {
      apiService.getQuestionDetails(id)
        .then((response) => {
          this.question = response.data;    
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    submitForm() {
      apiService.editQuestion(this.question)
      .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t('Your question has been updated successfully'),
            confirmButtonText: this.$t('OK'),
          })
          .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getAskQuestionGuid();
    this.getQuestionDetails(this.$route.params.questionId);
  },

}
</script>